import React from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import tw from 'twin.macro';
import { StyledLink } from '@components/layout/style';

export const Main = styled('main')`
  ${tw` flex justify-center items-center w-screen h-screen flex-col`}

`;

const NotFound: React.FC<PageProps> = () => (
  <Main>
    <p>Przepraszamy strony, nie ma strony któej szukasz!</p>
    <StyledLink>Wróc do nas :)</StyledLink>
  </Main>
);

export default NotFound;
